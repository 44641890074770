<template>
	<div class="pupup_wrap pt-50 pb-30">
		<div class="container">
			<div class="row">
				<div class="main_card_wrap">
					<div class="card_set">
						<div class="dark_checks hide">
							<input type="checkbox" id="mainCard">
							<label for="mainCard">메인카드 설정</label>
						</div>
						<a @click="$emit('to' , { name: 'mafia020', params: {card_type: 'utility', id: $route.params.id}} )">NFT 상세정보 <i class="icon icon-arrow-right"></i></a>
					</div>
					<div class="main_card">
						<figure class="nft-card-info">
							<img
								v-if="item.nft_token_id"
								:src="$request.upload_url(item.nft_card_img_url)"
								:alt="item.nft_card_name"
							/>
							<figcaption>
								<strong>{{  item.nft_card_name  }}</strong>
								<p>{{  item.nft_card_desctn }}</p>
							</figcaption>
						</figure>
						<span
							v-if="item.utilty_card_mount_fg == 'Y'"
							class="item_choice_tit"
						><em class="hide">아이템장착</em></span>
						<div class="card_badge">
							<span class="badge_40 " :class="'badge_40_' + item.type "><em class="hide">music</em></span>
							<span class="badge_40 badge_40_capo hide"><em class="hide">capo</em></span>
						</div>
					</div>
				</div>
				<div class="btn_area">
					<button
						v-if="item.utilty_card_mount_fg == 'Y'"
						class="btn_l btn_fill_blue" @click="doDismount"
					>해제</button>
					<button
						v-else
						class="btn_l btn_fill_blue" @click="doDismount"
					>장착</button>
				</div>
			</div>
		</div>

		<mafia0193
			v-if="is_0193"

			@cancel="is_0193 = !is_0193"
			@click="doPin('present')"
		></mafia0193>
	</div>
</template>

<script>

import mafia0193 from "@/view/Nft/mafia019-3";

export default{
	name: 'mafia019-2'
	, props: ['Axios', 'sample', 'user']
	, components: {mafia0193 }
	, data: function(){
		return {
			program: {
				name: '유틸리티 카드'
				, type: 'popup'
				, title: 'UTILITIES CARD'
				, from: 'mafia018'
			}
			, is_0193: false
			, pin_option: {

			}
			, item: {}
		}
	}
	,methods: {
		doWithdrawal: function(){
			this.$bus.$emit('notify',  { type: 'success', message: '출금요청이 완료되었습니다'})
		}
		, doPresent: function(){
			this.$bus.$emit('notify',  { type: 'success', message: '선물하기가 완료되었습니다'})
		}
		, doStaking: function(){
			this.$bus.$emit('notify',  { type: 'success', message: '스테이킹 처리가 완료되었습니다'})
		}
		,doPin: function(div){
			this.is_pin = true
			this.pin_option = {
				pin_type: 'check'
				, div: div
			}
			this.is_024 = false
			this.is_0193 = false
			this.is_03347 = false
		}
		,pinClick: function(){
			this.is_pin = false

			if(this.pin_option.div == 'withdrawal'){
				this.doWithdrawal()
			}else if(this.pin_option.div == 'present'){
				this.doPresent()
			}else if(this.pin_option.div == 'staking'){
				this.doStaking()
			}
			this.pin_option= {}
		}
		,pinCancel: function(){
			this.is_pin = false
			this.pin_option= {}
		}
		,getData: async function() {
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.get_utility_detail
					,data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postMount: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: ''
					, data: this.item
					, name: 'updateUtility'
				})

				if (result.success) {
					await this.getData()
				} else {
					this.$bus.$emit('notify',  {type: 'error', message: result.message})
				}
			} catch (e) {
				this.$bus.$emit('notify',  {type: 'error', message: this.$language.common.error})
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, doDismount: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_utility_flag
					, data: {
						member_number: this.user.member_number
						, utilty_nft_card_number: this.$route.params.id
						, reg_release_fg: this.item.utilty_card_mount_fg == 'Y' ? 'N' : 'Y'
					}
					, type: true
				})

				if(result.success){
					this.$emit('click')
					await this.getData()
				}else{
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify',  {type: 'error', message: this.$language.common.error})
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}

</script>